import React, {FC} from 'react';
import s from "../RequisitesPage.module.css"


type PropsType = {};
export const RequisitesTable: FC<PropsType> = (props) => {


    const requisites = [
        {id: 1, title: "Название организации", info: "ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ УГАЙ \n ИРИНА ВЛАДИСЛАВОВНА"},
        {id: 2, title: "Юридический адрес организации", info: "692501, РОССИЯ, ПРИМОРСКИЙ КРАЙ, Г \n УССУРИЙСК, УЛ НЕСТЕРЕНКО, Д 20"},
        {id: 3, title: "ИНН", info: "251131034404"},
        {id: 4, title: "Расчетный счет", info: "40802810100003519433"},
        {id: 5, title: "ОГРН", info: "322253600051154"},
        {id: 6, title: "Банк", info: "АО «Тинькофф Банк»"},
        {id: 7, title: "БИК банка", info: "044525974"},
        {id: 8, title: "ИНН банка", info: "7710140679"},
        {id: 9, title: "Корреспондентский счет банка", info: "30101810145250000974"},
        {id: 10, title: "Юридический адрес банка", info: "127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26"},
    ]

    return (
        <div className={s.table}>
            {requisites.map((requisite, index) =>
            <div className={s.tableItem} key={requisite.id} style={{background: `${!(index % 2) ? "#f7fafb" : "#fff"}`}}>
                <div className={s.tableItemTitle}>
                    {requisite.title}
                </div>
                <div className={s.tableItemInfo}>
                    {requisite.info}
                </div>
            </div>
            )}
        </div>
    );
};
