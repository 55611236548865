import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import appReducer from './AppReducer';
import MainPageReducer from './MainPageReducer';
import tradingBotReducer from './TradingBotReducer';
import { TradingBotInitialStateType } from './TradingBotReducer'
import { AppDataInitialStateType } from './AppReducer'
import { MainPageInitialStateType } from './MainPageReducer'

let rootReducer = combineReducers({
    appData: appReducer,
    mainPageData: MainPageReducer,
    tradingBotData: tradingBotReducer,
});
export type AppStateType = {
    appData:  AppDataInitialStateType,
    mainPageData: MainPageInitialStateType,
    tradingBotData :TradingBotInitialStateType
};

//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers( applyMiddleware(thunkMiddleware)))
//@ts-ignore
window.__store__ = store;
export default store;
