import React, {FC} from 'react';
import s from "./RequisitesPage.module.css"
import {Header} from "../Header/Header";
import {RequisitesTable} from "./RequisitesTable/RequisitesTable";
// import pdf from "./Requisites_Aitrix.pdf"

type PropsType = {};
export const RequisitesPage: FC<PropsType> = (props) => {

    const downloadPDF = () => {
        const fileUrl = './Requisites_Aitrix.pdf';
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = 'Requisites_Aitrix.pdf'; // Укажите желаемое имя для файла
        a.click();
    }

    return (
        <section className={s.RequisitesPage}>
            <div className={s.headerWrap}>
                <Header />
            </div>
            <div className="container">
                <div className={s.title}>
                    Реквизиты
                </div>
                <div className={s.subTitle}>
                    Счета и квитанции на оплату автоматически формируются с актуальными <br/> реквизитами.
                </div>
                <div className={s.dFlex}>
                    <div onClick={downloadPDF} className={s.downloadBtn}>
                        Сохранить как PDF
                    </div>
                </div>
                <RequisitesTable />
            </div>
        </section>
    );
};
