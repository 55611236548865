import React, {useState} from 'react';
import s from "./ContactsCom.module.css"
import {FaTelegram} from "react-icons/fa6";
import {FaTelegramPlane} from "react-icons/fa";
import {IoClose} from "react-icons/io5";


const ContactsCom = () => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <div className={s.iconToggle}>
                {/*<div className={s.ripple}></div>*/}
                <div className={s.tgRipple}></div>
                <div className={s.icon} onClick={() => setIsOpen(!isOpen)}>
                    {/*<FaWhatsapp className={s.whatsapp}/>*/}
                    <FaTelegram className={s.telegram}/>
                </div>
            </div>
                <div className={`${s.modal} ${isOpen && s.show}`} onClick={() => setIsOpen(false)}>
                    <div className={s.modalContent} onClick={e => e.stopPropagation()}>
                        <div onClick={() => setIsOpen(false)} className={s.closeIconWrap}>
                            <IoClose className={s.closeIcon}/>
                        </div>
                        <div className={s.title}>
                            Напишите нам
                        </div>
                        <div className={s.btns}>
                            {/*<a href={"https://wa.me/79954702377"} target="_blank" className={s.btn} style={{background: "#5ed169"}}>*/}
                            {/*    <FaWhatsapp className={s.modalWPicon}/>*/}
                            {/*    <div className={s.btnText}>*/}
                            {/*        Написать в WhatsApp*/}
                            {/*    </div>*/}
                            {/*</a>*/}
                            <a href={"https://t.me/Lts_hg"} target="_blank" className={s.btn} style={{background: "#2aabee"}}>
                                <FaTelegramPlane className={s.modalTGicon}/>
                                <div className={s.btnText}>
                                    Написать в Telegram
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default ContactsCom;