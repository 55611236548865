import React, {FC, useState} from 'react';
import s from "../MainPage/MainPage.module.css";
import logo_img from "../../img/logo.png";
import {Link} from "react-router-dom";

type PropsType = {};

export const Header: FC<PropsType> = (props) => {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <header className={`${s.header}`}>
            <div className={s.headerBurgerIcon} onClick={() => setIsOpen(!isOpen)}>
                <div style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1
                }}>
                    <svg width="34" height="34" viewBox="0 0 24 24" style={{fill: "rgba(255, 255, 255, 1)"}}>
                        <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                    </svg>
                    <div className={s.header__logo}>
                        <a href="/"><img src={logo_img} alt="logo_img"/></a>
                    </div>
                </div>
            </div>
            <div className={s.headerBurgerModal} style={{transform: `${isOpen ? "translateX(0)" : "translateX(-100%)"}`}}>
                <div className={s.headerBurgerModalContent}>
                    <svg width="24" height="24" viewBox="0 0 24 24" onClick={() => setIsOpen(!isOpen)}
                         style={{fill: "rgba(255, 255, 255, 1)"}}>
                        <path
                            d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                    </svg>
                    <ul className={s.header_burger_nav}>
                        <Link to={"/#about"} onClick={() => setIsOpen(!isOpen)}><span>О нас</span></Link>
                        <Link to={"/#services"} onClick={() => setIsOpen(!isOpen)}><span>Услуги</span></Link>
                        <Link to={"/#advantage"} onClick={() => setIsOpen(!isOpen)}><span>Преимущества</span></Link>
                        <Link to={"/#stages"} onClick={() => setIsOpen(!isOpen)}><span>Этапы работы</span></Link>
                        <Link to={"/#contact"} onClick={() => setIsOpen(!isOpen)}><span>Контакты</span></Link>
                        <Link to={"/requisites"} onClick={() => setIsOpen(!isOpen)}><span>Реквизиты</span></Link>
                    </ul>
                </div>
            </div>
            <div className={s.header__logo + " " + s.noneMobile}>
                <a href="/"><img src={logo_img} alt="logo_img"/></a>
            </div>
            <ul className={s.header__nav}>
                <Link to={"/#about"}><span>О нас</span></Link>
                <Link to={"/#services"}><span>Услуги</span></Link>
                <Link to={"/#advantage"}><span>Преимущества</span></Link>
                <Link to={"/#stages"}><span>Этапы работы</span></Link>
                <Link to={"/#contact"}><span>Контакты</span></Link>
                <Link to={"/requisites"}><span>Реквизиты</span></Link>
            </ul>
        </header>
    );
};

