import '../css/bootstrap.min.css'
import '../css/reset.css'
import 'animate.css';
import '../css/App.css';


import React, {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {initializeTC} from '../redux/AppReducer';
import {MainPage} from './MainPage/MainPage';
import {Route, Switch} from 'react-router-dom';
import {RequisitesPage} from "./RequisitesPage/RequisitesPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorksPage from "./WorksPage/WorksPage";


type PropsType = {}
const App: FC<PropsType> = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(initializeTC())
    }, [])

    return (
        <div className={`app`}>
            <Switch>
                <Route exact path="/" render={() => <MainPage/>}/>
                <Route exact path="/requisites" render={() => <RequisitesPage/>}/>
                {/*<Route exact path="/works" render={() => <WorksPage/>}/>*/}
            </Switch>
            {/* <Route exact path="/tradingBot" render={()=><TradingBotLanding/>}/>
      <Route exact path="/tradingBot/login" render={()=><TradingBotLogin/>}/>
      <Route exact path="/tradingBot/registr" render={()=><TradingBotRegistr/>}/>
      <Route path="/tradingBot/account" render={()=><TradingBotAccount/>}/> */}
        </div>
    );
}

export default App
